
import { useEffect, useState } from 'react';
import { surveys } from './index.js';
import './App.css';

const logo = <svg className="logo"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 431.45 107.59">
<path className="cls-1" d="m38.78,98.81c-3.45,1.47-7.1,2.21-10.96,2.21s-7.52-.74-10.98-2.21c-3.46-1.47-6.42-3.45-8.88-5.93-2.46-2.48-4.4-5.43-5.83-8.84-1.43-3.41-2.14-7.02-2.14-10.81s.71-7.39,2.14-10.79c1.42-3.4,3.37-6.33,5.83-8.79,2.46-2.46,5.41-4.41,8.86-5.86s7.11-2.17,11-2.17,7.52.72,10.96,2.17,6.4,3.4,8.84,5.86,4.39,5.39,5.83,8.79c1.44,3.4,2.15,7,2.15,10.79s-.72,7.37-2.15,10.79c-1.44,3.42-3.38,6.38-5.83,8.86-2.45,2.48-5.4,4.46-8.84,5.93Zm-10.96-12.82c3.22,0,6-1.23,8.36-3.69,2.36-2.46,3.53-5.47,3.53-9.03s-1.19-6.5-3.57-8.95-5.15-3.67-8.33-3.67-5.98,1.22-8.34,3.67-3.55,5.43-3.55,8.95,1.19,6.57,3.57,9.03c2.38,2.46,5.15,3.69,8.33,3.69Z"/>
<path className="cls-1" d="m74.47,63.96l4.24,38.1c.12,1.05-.35,1.63-1.4,1.75l-12.61,1.4c-1.05.12-1.63-.35-1.75-1.4l-5.55-49.85c-.12-1.05.35-1.63,1.4-1.75l43.82-4.88c1.07-.12,1.67.35,1.79,1.4l5.55,49.85c.12,1.05-.36,1.64-1.44,1.76l-12.57,1.4c-1.07.12-1.67-.35-1.79-1.4l-4.24-38.1-15.45,1.72Z"/>
<path className="cls-1" d="m130.9,79.45l-1.16,14.57c-.08,1.05-.65,1.54-1.71,1.45l-12.44-.99c-1.05-.08-1.54-.65-1.45-1.71l3.99-50c.08-1.05.65-1.54,1.71-1.45l21.24,1.69c5.84.47,10.41,2.55,13.72,6.25,3.3,3.7,4.74,8.16,4.33,13.38-.45,5.7-2.54,10.15-6.25,13.34-3.71,3.19-8.57,4.54-14.57,4.06l-7.39-.59Zm1.83-22.89l-.7,8.8,6.12.49c1.35.11,2.55-.25,3.58-1.08,1.03-.83,1.6-1.9,1.71-3.2.09-1.19-.3-2.21-1.19-3.07-.89-.85-2.01-1.34-3.36-1.44l-6.15-.49Z"/>
<path className="cls-1" d="m198.83,101.73c-3.45,1.47-7.1,2.21-10.96,2.21s-7.52-.74-10.98-2.21c-3.46-1.47-6.42-3.45-8.88-5.93-2.46-2.48-4.4-5.43-5.83-8.84-1.43-3.41-2.14-7.02-2.14-10.81s.71-7.39,2.14-10.79c1.42-3.4,3.37-6.33,5.83-8.79,2.46-2.46,5.41-4.41,8.86-5.86s7.11-2.17,11-2.17,7.52.72,10.96,2.17,6.4,3.4,8.84,5.86,4.39,5.39,5.83,8.79c1.44,3.4,2.15,7,2.15,10.79s-.72,7.37-2.15,10.79c-1.44,3.42-3.38,6.38-5.83,8.86-2.45,2.48-5.4,4.46-8.84,5.93Zm-10.96-12.82c3.22,0,6-1.23,8.36-3.69,2.36-2.46,3.53-5.47,3.53-9.03s-1.19-6.5-3.57-8.95-5.15-3.67-8.33-3.67-5.98,1.22-8.34,3.67-3.55,5.43-3.55,8.95,1.19,6.57,3.57,9.03c2.38,2.46,5.15,3.69,8.33,3.69Z"/>
<path className="cls-1" d="m250.81,97.88c-3.81.62-7.54.48-11.19-.41-3.65-.89-6.89-2.37-9.72-4.42-2.83-2.05-5.22-4.65-7.17-7.79-1.96-3.14-3.24-6.58-3.85-10.32s-.49-7.41.37-10.99c.86-3.59,2.3-6.79,4.34-9.61,2.03-2.82,4.63-5.23,7.8-7.21s6.67-3.29,10.5-3.91c6.24-1.02,12.04-.13,17.41,2.65.92.5,1.14,1.21.66,2.13l-5.61,10.56c-.47.84-1.19,1.08-2.16.7-2.78-1.2-5.41-1.6-7.88-1.2-3.15.51-5.7,2.17-7.64,4.97s-2.63,5.93-2.06,9.4,2.23,6.3,4.98,8.34c2.74,2.04,5.68,2.81,8.81,2.3,1.52-.25,2.8-.63,3.83-1.15s2.14-1.36,3.32-2.53c.78-.76,1.55-.78,2.3-.06l8.55,8.28c.75.72.8,1.43.17,2.14-4.22,4.41-9.47,7.13-15.75,8.16Z"/>
<path className="cls-1" d="m286.44,83.18l-2.5,18.83c-.14,1.05-.73,1.5-1.78,1.36l-12.37-1.64c-1.05-.14-1.5-.73-1.36-1.78l6.59-49.73c.14-1.05.73-1.5,1.78-1.36l12.37,1.64c1.05.14,1.5.73,1.36,1.78l-2.13,16.03,16.06,2.13,2.13-16.03c.14-1.05.73-1.5,1.78-1.36l12.41,1.64c1.03.14,1.47.73,1.33,1.78l-6.59,49.73c-.14,1.05-.72,1.5-1.75,1.37l-12.41-1.64c-1.05-.14-1.5-.73-1.36-1.78l2.5-18.83-16.06-2.13Z"/>
<path className="cls-1" d="m358.14,46l12.81-1.11c1.05-.09,1.63.39,1.72,1.44l4.32,49.98c.09,1.05-.39,1.63-1.44,1.72l-12.02,1.04c-1.08.09-1.66-.39-1.75-1.44l-2.33-26.96-14.56,28.94c-.37.75-.97,1.16-1.8,1.23l-11.27.97c-1.05.09-1.63-.39-1.72-1.44l-4.32-49.98c-.09-1.05.39-1.63,1.44-1.72l11.99-1.04c1.05-.09,1.63.39,1.72,1.44l2.16,24.97,13.18-26.82c.37-.75,1-1.16,1.87-1.23Z"/>
<path className="cls-1" d="m397.05,78.66l-1.63,19c-.09,1.05-.66,1.53-1.72,1.44l-12.64-1.08c-1.05-.09-1.53-.66-1.44-1.72l4.29-49.98c.09-1.05.66-1.53,1.72-1.44l12.64,1.08c1.05.09,1.53.66,1.44,1.72l-1.51,17.59,4.33.37,11.82-17.19c.47-.7,1.14-1.01,1.98-.94l13.84,1.19c.62.05,1.02.26,1.19.62.18.36.07.81-.32,1.36l-17.41,22.66,13.24,26.71c.28.55.29,1.01.06,1.35-.24.35-.67.5-1.28.44l-13.91-1.19c-.87-.07-1.47-.51-1.79-1.3l-8.57-20.35-4.33-.34Z"/>
<g>
  <path className="cls-1" d="m16.39,7.17v25.96c0,.58-.29.86-.88.86h-2.16c-.58,0-.86-.29-.86-.86V7.17H3.63c-.58,0-.86-.29-.86-.86v-1.85c0-.58.29-.86.86-.86h21.6c.58,0,.86.29.86.86v1.85c0,.58-.29.86-.86.86h-8.84Z"/>
  <path className="cls-1" d="m40.29,7.68c1.51,0,3.13.47,4.87,1.4.48.29.6.68.35,1.18l-.84,1.67c-.26.5-.66.6-1.18.31-1.26-.65-2.47-.98-3.64-.98-1.73,0-3.25.85-4.56,2.56-1.31,1.71-1.97,3.86-1.97,6.46v12.85c0,.58-.29.86-.86.86h-1.96c-.58,0-.86-.29-.86-.86V9c0-.58.29-.86.86-.86h1.69c.58,0,.87.28.88.85l.08,2.75c.58-.84,1.16-1.53,1.76-2.08.6-.55,1.36-1.02,2.29-1.4s1.96-.57,3.09-.57Z"/>
  <path className="cls-1" d="m66.26,12.5l.04-3.52c.01-.56.3-.85.86-.85h1.87c.58,0,.86.29.86.86v24.13c0,.58-.29.86-.86.86h-1.85c-.56,0-.86-.28-.88-.85l-.04-3.48c-1,1.49-2.24,2.67-3.74,3.54s-3.22,1.3-5.16,1.3c-2.35,0-4.46-.59-6.34-1.78-1.88-1.19-3.34-2.8-4.38-4.84-1.04-2.04-1.56-4.3-1.56-6.78s.52-4.74,1.57-6.78c1.05-2.04,2.51-3.66,4.39-4.85,1.88-1.19,3.99-1.79,6.32-1.79,1.95,0,3.68.43,5.18,1.29s2.74,2.03,3.72,3.53Zm-8.86,18.45c2.62,0,4.77-.93,6.46-2.79,1.68-1.86,2.52-4.19,2.52-7s-.84-5.19-2.52-7.07c-1.68-1.89-3.82-2.83-6.43-2.83-1.69,0-3.2.44-4.52,1.31-1.32.87-2.34,2.06-3.06,3.56-.71,1.5-1.07,3.18-1.07,5.04,0,2.83.8,5.17,2.39,7.02,1.59,1.85,3.67,2.77,6.22,2.77Z"/>
  <path className="cls-1" d="m88.43,7.66c3.13,0,5.63.99,7.49,2.96,1.86,1.97,2.79,4.63,2.79,7.97v14.54c0,.58-.29.86-.86.86h-1.97c-.58,0-.86-.29-.86-.86v-14.17c0-2.34-.65-4.21-1.96-5.61-1.3-1.4-3.04-2.09-5.22-2.09s-4.04.74-5.6,2.22-2.34,3.31-2.34,5.48v14.17c0,.58-.29.86-.86.86h-1.96c-.58,0-.86-.29-.86-.86V9c0-.58.29-.86.86-.86h1.69c.58,0,.87.28.88.85l.06,2.75c.93-1.18,2.19-2.15,3.78-2.92,1.59-.77,3.24-1.15,4.94-1.15Z"/>
  <path className="cls-1" d="m111.79,34.59c-1.82,0-3.55-.32-5.19-.95-1.64-.64-3.09-1.49-4.36-2.56-.42-.37-.45-.77-.1-1.2l1.1-1.43c.35-.45.76-.49,1.22-.14,2.48,1.81,4.88,2.71,7.21,2.71,1.56,0,2.83-.37,3.81-1.12.98-.75,1.47-1.74,1.47-2.99,0-.51-.1-.97-.28-1.37-.19-.4-.44-.74-.76-1.02s-.75-.55-1.32-.82c-.56-.26-1.14-.48-1.72-.67-.58-.18-1.33-.39-2.23-.63-5.34-1.39-8.02-3.96-8.02-7.7,0-.72.1-1.41.31-2.07.21-.66.55-1.3,1.02-1.91.47-.61,1.04-1.14,1.72-1.58s1.51-.8,2.51-1.06c1-.26,2.1-.39,3.3-.39,1.32,0,2.68.24,4.06.73,1.38.48,2.58,1.16,3.59,2.02.41.35.41.76.02,1.22l-1.16,1.4c-.35.41-.76.44-1.22.1-1.7-1.26-3.51-1.89-5.42-1.89-1.73,0-3.02.33-3.87.98-.85.66-1.28,1.5-1.28,2.54s.46,1.84,1.37,2.49c.91.65,2.52,1.29,4.84,1.92.94.25,1.79.52,2.54.81.75.29,1.48.65,2.2,1.09.72.44,1.32.92,1.8,1.44.48.52.86,1.16,1.15,1.9.29.74.43,1.56.43,2.45,0,2.14-.81,3.96-2.42,5.46-1.61,1.51-3.72,2.26-6.33,2.26Z"/>
  <path className="cls-1" d="m128.2,1.04v32.09c0,.58-.29.86-.86.86h-1.96c-.58,0-.86-.29-.86-.86V1.04c0-.58.29-.86.86-.86h1.96c.58,0,.86.29.86.86Z"/>
  <path className="cls-1" d="m153.99,12.5l.04-3.52c.01-.56.3-.85.86-.85h1.87c.58,0,.87.29.87.86v24.13c0,.58-.29.86-.87.86h-1.85c-.56,0-.86-.28-.88-.85l-.04-3.48c-.99,1.49-2.24,2.67-3.74,3.54s-3.22,1.3-5.16,1.3c-2.35,0-4.46-.59-6.34-1.78-1.88-1.19-3.34-2.8-4.38-4.84-1.04-2.04-1.56-4.3-1.56-6.78s.52-4.74,1.57-6.78c1.05-2.04,2.51-3.66,4.39-4.85,1.88-1.19,3.99-1.79,6.32-1.79,1.95,0,3.68.43,5.18,1.29s2.74,2.03,3.72,3.53Zm-8.86,18.45c2.62,0,4.77-.93,6.46-2.79,1.68-1.86,2.53-4.19,2.53-7s-.84-5.19-2.52-7.07c-1.68-1.89-3.82-2.83-6.43-2.83-1.69,0-3.2.44-4.52,1.31-1.32.87-2.34,2.06-3.06,3.56-.71,1.5-1.07,3.18-1.07,5.04,0,2.83.8,5.17,2.39,7.02s3.67,2.77,6.22,2.77Z"/>
  <path className="cls-1" d="m168.41,11.59v21.54c0,.58-.29.86-.87.86h-2c-.58,0-.87-.29-.87-.86V11.59h-2.4c-.58,0-.86-.29-.86-.86v-1.73c0-.58.29-.86.86-.86h2.38V3.65c0-.54.26-.84.79-.92l2.02-.35c.29-.05.52,0,.69.13s.26.36.26.66v4.97h4.89c.58,0,.87.29.87.86v1.73c0,.58-.29.86-.87.86h-4.89Z"/>
  <path className="cls-1" d="m188.14,34.59c-1.73,0-3.38-.35-4.95-1.06-1.57-.71-2.93-1.66-4.06-2.85-1.13-1.19-2.03-2.62-2.7-4.27-.67-1.66-1-3.4-1-5.22,0-2.46.52-4.73,1.57-6.79s2.49-3.7,4.33-4.9c1.84-1.21,3.87-1.81,6.08-1.81,2.37,0,4.46.59,6.27,1.77,1.81,1.18,3.19,2.79,4.15,4.82s1.43,4.33,1.43,6.87v.9c-.03.42-.32.63-.88.63h-19.24c.3,2.52,1.32,4.53,3.07,6.05,1.74,1.52,3.77,2.28,6.09,2.28,2.48,0,4.71-.82,6.7-2.46.47-.41.88-.37,1.22.12l1.02,1.49c.31.46.27.86-.14,1.22-2.55,2.14-5.54,3.2-8.96,3.2Zm-8.98-15.37h16.37c-.31-2.46-1.19-4.4-2.61-5.83s-3.24-2.13-5.42-2.13-3.9.73-5.46,2.18c-1.56,1.45-2.51,3.38-2.87,5.78Z"/>
  <path className="cls-1" d="m234.46,29.12v-7.98c0-.58.29-.86.86-.86h2.14c.58,0,.86.29.86.86v9.27c0,.46-.18.81-.53,1.04-1.36.96-2.92,1.7-4.66,2.24-1.74.54-3.49.81-5.23.81-2.15,0-4.19-.41-6.13-1.24-1.94-.83-3.6-1.94-4.99-3.34-1.39-1.4-2.49-3.07-3.3-5.01-.81-1.94-1.22-3.99-1.22-6.15s.41-4.19,1.22-6.13c.81-1.94,1.91-3.61,3.3-5,1.39-1.4,3.05-2.5,4.99-3.32,1.94-.82,3.98-1.23,6.13-1.23,3.56,0,6.75,1.08,9.55,3.24.43.35.47.75.12,1.2l-1.26,1.63c-.35.43-.75.48-1.2.16-2.19-1.66-4.59-2.5-7.21-2.5-2.11,0-4.06.53-5.86,1.58-1.79,1.05-3.22,2.5-4.27,4.33-1.05,1.83-1.58,3.84-1.58,6.03,0,1.65.31,3.23.93,4.73.62,1.5,1.46,2.78,2.51,3.85s2.3,1.92,3.74,2.54c1.44.63,2.95.94,4.52.94,2.31,0,4.49-.57,6.54-1.71Z"/>
  <path className="cls-1" d="m261.7,23.17v-14.17c0-.58.29-.86.87-.86h1.96c.58,0,.86.29.86.86v24.13c0,.58-.29.86-.86.86h-1.71c-.55,0-.84-.28-.86-.85l-.1-2.75c-.93,1.18-2.18,2.15-3.76,2.92-1.58.77-3.22,1.15-4.92,1.15-3.14,0-5.66-.99-7.55-2.97s-2.83-4.63-2.83-7.96v-14.54c0-.58.29-.86.87-.86h2.06c.58,0,.87.29.87.86v14.17c0,2.35.65,4.21,1.95,5.61,1.3,1.4,3.03,2.09,5.19,2.09s4.06-.74,5.63-2.22c1.57-1.48,2.35-3.31,2.35-5.48Z"/>
  <path className="cls-1" d="m291.17,12.5l.04-3.52c.01-.56.3-.85.86-.85h1.87c.58,0,.86.29.86.86v24.13c0,.58-.29.86-.86.86h-1.85c-.56,0-.86-.28-.88-.85l-.04-3.48c-1,1.49-2.24,2.67-3.74,3.54s-3.22,1.3-5.16,1.3c-2.35,0-4.46-.59-6.34-1.78-1.88-1.19-3.34-2.8-4.38-4.84-1.04-2.04-1.56-4.3-1.56-6.78s.52-4.74,1.57-6.78c1.05-2.04,2.51-3.66,4.39-4.85,1.88-1.19,3.99-1.79,6.32-1.79,1.95,0,3.68.43,5.18,1.29s2.74,2.03,3.72,3.53Zm-8.86,18.45c2.62,0,4.77-.93,6.46-2.79,1.68-1.86,2.53-4.19,2.53-7s-.84-5.19-2.52-7.07c-1.68-1.89-3.82-2.83-6.43-2.83-1.69,0-3.2.44-4.52,1.31-1.32.87-2.34,2.06-3.06,3.56s-1.07,3.18-1.07,5.04c0,2.83.8,5.17,2.39,7.02s3.67,2.77,6.22,2.77Z"/>
  <path className="cls-1" d="m311.77,7.68c1.51,0,3.13.47,4.87,1.4.48.29.6.68.35,1.18l-.84,1.67c-.26.5-.66.6-1.18.31-1.26-.65-2.47-.98-3.63-.98-1.73,0-3.25.85-4.56,2.56-1.31,1.71-1.96,3.86-1.96,6.46v12.85c0,.58-.29.86-.86.86h-1.97c-.58,0-.86-.29-.86-.86V9c0-.58.29-.86.86-.86h1.69c.58,0,.87.28.88.85l.08,2.75c.58-.84,1.16-1.53,1.76-2.08.6-.55,1.36-1.02,2.29-1.4s1.96-.57,3.08-.57Z"/>
  <path className="cls-1" d="m337.66,12.2V1.04c0-.58.29-.86.86-.86h1.99c.58,0,.86.29.86.86v32.09c0,.58-.29.86-.86.86h-1.85c-.59,0-.88-.28-.88-.85l-.04-3.48c-1,1.49-2.24,2.67-3.74,3.54s-3.22,1.3-5.16,1.3c-2.35,0-4.46-.59-6.34-1.78-1.88-1.19-3.34-2.8-4.38-4.84-1.04-2.04-1.56-4.3-1.56-6.78s.52-4.74,1.57-6.78c1.05-2.04,2.51-3.66,4.39-4.85,1.88-1.19,3.99-1.79,6.32-1.79,1.9,0,3.6.41,5.11,1.22,1.51.81,2.74,1.91,3.71,3.3Zm-8.78,18.75c2.62,0,4.77-.93,6.46-2.79,1.68-1.86,2.53-4.19,2.53-7s-.84-5.19-2.52-7.07c-1.68-1.89-3.82-2.83-6.43-2.83-1.69,0-3.2.44-4.52,1.31-1.32.87-2.34,2.06-3.06,3.56s-1.07,3.18-1.07,5.04c0,2.83.8,5.17,2.39,7.02s3.67,2.77,6.22,2.77Z"/>
  <path className="cls-1" d="m350.52,3.81h-1.97c-.58,0-.86-.29-.86-.86V.86c0-.58.29-.86.86-.86h1.97c.58,0,.86.29.86.86v2.08c0,.58-.29.86-.86.86Zm.86,5.19v24.13c0,.58-.29.86-.86.86h-1.97c-.58,0-.86-.29-.86-.86V9c0-.58.29-.86.86-.86h1.97c.58,0,.86.29.86.86Z"/>
  <path className="cls-1" d="m377.17,12.5l.04-3.52c.01-.56.3-.85.86-.85h1.87c.58,0,.86.29.86.86v24.13c0,.58-.29.86-.86.86h-1.85c-.56,0-.86-.28-.88-.85l-.04-3.48c-1,1.49-2.24,2.67-3.74,3.54s-3.22,1.3-5.16,1.3c-2.35,0-4.46-.59-6.34-1.78-1.88-1.19-3.34-2.8-4.38-4.84-1.04-2.04-1.56-4.3-1.56-6.78s.52-4.74,1.57-6.78c1.05-2.04,2.51-3.66,4.39-4.85,1.88-1.19,3.99-1.79,6.32-1.79,1.95,0,3.68.43,5.18,1.29s2.74,2.03,3.72,3.53Zm-8.86,18.45c2.62,0,4.77-.93,6.46-2.79,1.68-1.86,2.53-4.19,2.53-7s-.84-5.19-2.52-7.07c-1.68-1.89-3.82-2.83-6.43-2.83-1.69,0-3.2.44-4.52,1.31-1.32.87-2.34,2.06-3.06,3.56s-1.07,3.18-1.07,5.04c0,2.83.8,5.17,2.39,7.02s3.67,2.77,6.22,2.77Z"/>
  <path className="cls-1" d="m399.35,7.66c3.13,0,5.63.99,7.49,2.96,1.86,1.97,2.79,4.63,2.79,7.97v14.54c0,.58-.29.86-.86.86h-1.96c-.58,0-.87-.29-.87-.86v-14.17c0-2.34-.65-4.21-1.96-5.61-1.3-1.4-3.04-2.09-5.22-2.09s-4.04.74-5.6,2.22c-1.56,1.48-2.34,3.31-2.34,5.48v14.17c0,.58-.29.86-.86.86h-1.97c-.58,0-.86-.29-.86-.86V9c0-.58.29-.86.86-.86h1.69c.58,0,.87.28.88.85l.06,2.75c.93-1.18,2.19-2.15,3.78-2.92,1.59-.77,3.24-1.15,4.94-1.15Z"/>
  <path className="cls-1" d="m422.7,34.59c-1.82,0-3.55-.32-5.19-.95-1.64-.64-3.09-1.49-4.36-2.56-.42-.37-.45-.77-.1-1.2l1.1-1.43c.35-.45.76-.49,1.22-.14,2.48,1.81,4.88,2.71,7.21,2.71,1.56,0,2.83-.37,3.81-1.12.98-.75,1.47-1.74,1.47-2.99,0-.51-.09-.97-.29-1.37-.19-.4-.44-.74-.76-1.02s-.75-.55-1.32-.82c-.56-.26-1.14-.48-1.72-.67-.58-.18-1.33-.39-2.23-.63-5.35-1.39-8.02-3.96-8.02-7.7,0-.72.1-1.41.31-2.07.21-.66.55-1.3,1.02-1.91.47-.61,1.04-1.14,1.72-1.58.67-.45,1.51-.8,2.5-1.06,1-.26,2.1-.39,3.3-.39,1.32,0,2.68.24,4.06.73,1.38.48,2.58,1.16,3.59,2.02.41.35.41.76.02,1.22l-1.16,1.4c-.35.41-.76.44-1.22.1-1.7-1.26-3.51-1.89-5.42-1.89-1.73,0-3.02.33-3.87.98-.85.66-1.28,1.5-1.28,2.54s.46,1.84,1.37,2.49c.91.65,2.53,1.29,4.84,1.92.94.25,1.79.52,2.54.81.75.29,1.48.65,2.2,1.09s1.32.92,1.8,1.44c.48.52.86,1.16,1.15,1.9.29.74.43,1.56.43,2.45,0,2.14-.81,3.96-2.42,5.46-1.61,1.51-3.72,2.26-6.33,2.26Z"/>
</g>
</svg>

let isAnswered = true
let id;
let localList;

function App() {
  const [input1, setInput1] = useState("")
  const [input2, setInput2] = useState("")
  const [input3, setInput3] = useState("")
  const [nickname, setNickname] = useState("")
  const [question, setQuestion] = useState("")
  const [isOutOfQuestions, sooq]= useState(false)
  const [push_window, setpush_window] = useState()
  const [isPending, setPending] = useState(false)

  const sanitize = (text) => {
    const accepted = /[^а-яА-ЯёЁ\-]/g;
    return text.replace(accepted, "")
  }
  const codeOnInput1 = (event) => {
    setInput1(sanitize(event.target.value))
  };
  const codeOnInput2 = (event) => {
    setInput2(sanitize(event.target.value))
  };
  const codeOnInput3 = (event) => {
    setInput3(sanitize(event.target.value))
  };
  const onNicnameInput = (event) => {
    setNickname(event.target.value);
    localStorage.setItem("nickname", nickname)
  }

  const getQuestion = () => {
    if(!isAnswered) return
    if(surveys.length == 0){
      return "undefined"
    }
    let question = surveys[Math.floor(Math.random() * surveys.length)]
    id = question.id;
    try {
      localList = JSON.parse(localStorage.getItem("surveyArray")) || []
    } catch (error) {
      localList = []
    }
    
    if(localList.length >= (surveys.length)){console.log("out of questions"); sooq(true); return}
    while(localList.includes(id)){
      question = surveys[Math.floor(Math.random() * surveys.length)]
      id = question.id;
    }
    return question.prompt
  }

  useEffect(() => {
    setQuestion(getQuestion);
    setNickname(localStorage.getItem("nickname") || "")
  }, [])

  const gPW = (arg) => {
    return <div className='modalWindow' key={Math.random()}><h3>{arg}</h3></div>
  }

  const submitForm = async (event) => {
    event.preventDefault();

    if(input1 === input2 || input2 === input3 || input1 === input3){
      setpush_window(gPW("Ответы не должны повторятся."));
      return
    };
    if(!input1 || !input2 || !input3 || !nickname){
      setpush_window(gPW("Все поля должны быть заполнены."));
      return
    };
    localStorage.setItem("surveyArray", JSON.stringify(localList));
    const answer = {"answers": [id, nickname, input1, input2, input3]}
    console.log(answer)
    const postData = async (url, data = {"answers": [id, nickname, input1, input2, input3]}) => {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      return response.json(); 
    };

    if(!isPending){
      try {
        setPending(true)
        console.log("pending...")
        await postData('https://trgu.ru/survey/answer', answer);
        setPending(false)
        localList.push(id);
        setpush_window(gPW("Ответ отправлен. Спасибо!"));
        setQuestion(getQuestion);
        isAnswered = true;
        setInput1(""); setInput2(""); setInput3("");
      } catch (error) {
        setpush_window(gPW("Произошла ошибка. Проверьте своё интерне-соединение."));
        setPending(false)
        return
      }
    }

  };

  const handleEnterPress = (event) => {
    if(event.key === 'Enter') {
      event.preventDefault();
    }
  }

  const formSurvey = (
    <div className="App">
      <header className="App-header">
        {push_window}
        <div className="innerForm">
          {logo}
          <form onSubmit={submitForm} key={id} onKeyPress={handleEnterPress}>
            <label htmlFor="nickname" className='GigaFat'>Никнейм:</label>
            <input onInput={onNicnameInput} value={nickname} type="text" maxLength={25} id="nickname" name="nickname" placeholder="Введите ваш никнейм" autoComplete="off"/>
            <label className="entrywarning">Вы должны дать три различных ответа на русском языке, состоящих из 1 слова (или чего-то похожего на одно слово)</label>
            <label className="questionLabel" htmlFor="question">Ваш вопрос: {question}</label>
            <div className='answers'>
            <input maxLength={80} className='InputAnswer' value={input1} onInput={codeOnInput1} type="text" id="answer1" name="answer1" placeholder="Введите первый ответ" autoComplete="off"/>
            <input maxLength={80} className='InputAnswer' value={input2} onInput={codeOnInput2} type="text" id="answer2" name="answer2" placeholder="Введите второй ответ" autoComplete="off"/>
            <input maxLength={80} className='InputAnswer' value={input3} onInput={codeOnInput3} type="text" id="answer3" name="answer3" placeholder="Введите третий ответ" autoComplete="off"/>
            </div>
            <input className='GigaFat submitButton' type="submit" value="Отправить"/>
          </form>
        </div>
      </header>
    </div>
  );

  const thanks = (
    <div className="App">
      <header className="App-header">
        <div className="innerForm">
          {logo}
          <p>Спасибо за ответы, {nickname}!<br></br>Новые вопросы появятся совсем скоро.</p>    
        </div>
      </header>
    </div>
  );

  return isOutOfQuestions ? thanks : formSurvey
}

export default App;
