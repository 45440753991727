import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

let surveys = [];

const loadSurveys = async () => {
  try {
    const response = await axios.get('https://www.trgu.ru/trgu_surveys.json');
    const data = response.json();
    surveys = data.surveys;
  } catch (error) {
    console.error('Ошибка при загрузке данных:', error);
    surveys = [
      {"prompt": "Одним словом... Каким прилагательным вы бы могли описать своего отца?","id": "1"},
      {"prompt": "Одним словом... Назовите что-то, что поместилось бы у вас в носовой ноздре","id": "2"},
      {"prompt": "Одним словом... Назовите животное, в бою с которым вы бы одержали победу","id": "3"},
      {"prompt": "Одним словом... Назовите что-то, что было бы в вашем сендвиче","id": "4"},
      {"prompt": "Одним словом... Назовите хороший фильм, содержащий в названии всего одно слово","id": "5"},
      {"prompt": "Одним словом... Назовите что-то, что вы взяли бы с собой к чаю","id": "6"},
      {"prompt": "Одним словом... Назовите что-то, что вы могли бы найти у себя на даче","id": "7"},
      {"prompt": "Какое прилагательное у вас ассоциируется со словом \"театр\"?","id": "8"},
      {"prompt": "Одним словом... Назовите игру, в которой не нужен мяч","id": "9"},
      {"prompt": "Одним словом... Назовите слово, к которому подходит слово \"великий\"?","id": "10"},
      {"prompt": "Одним словом... Назовите спортивную игру, за которой интересно наблюдать по телевизору","id": "11"},
      {"prompt": "Одним словом... Назовите профессию, в которой, по-вашему мнению, не нужны какие-либо навыки","id": "12"},
      {"prompt": "Одним словом... Назовите что-то, за чем можно ухаживать","id": "13"},
      {"prompt": "Одним словом... Назовите знаменитую фамилию с окончанием на \"ский\"","id": "14"},
      {"prompt": "Одним словом... Назовите слово, к которому подходит слово \"богатый\"","id": "15"},
      {"prompt": "Одним словом... Назовите для своего случая концовку фразы \"я наконец-то научился...\"","id": "16"},
      {"prompt": "Одним словом... Назовите какую-нибудь очень известную старуху","id": "17"},
      {"prompt": "Одним словом... Назовите свой любимый вкус мороженого","id": "18"},
      {"prompt": "Одним словом... Назовите что-то, что можно купить в магазине по продаже технике","id": "19"},
      {"prompt": "Одним словом... Назовите слово, к которому подходит слово \"весёлый\"","id": "20"},
      {"prompt": "Одним словом... Назовите что-то, что можно оказывать","id": "21"},
      {"prompt": "Одним словом... Назовите кого-то или что-то, что можно увидеть в цирке","id": "22"},
      {"prompt": "Одним словом... Назовите что-то, что можно найти в вашей ванной комнате","id": "23"},
      {"prompt": "Одним словом... Назовите что-то белоснежное","id": "24"},
      {"prompt": "Одним словом... Назовите что-то, что может быть смешным","id": "25"},
      {"prompt": "Одним словом... Назовите что-то, что можно наполнить","id": "26"},
      {"prompt": "Одним словом... Назовите что-то очень дорогое, на что вы бы откладывали деньги","id": "27"},
      {"prompt": "Одним словом... Назовите что-то, что люди могут проиграть в казино","id": "28"},
      {"prompt": "Одним словом... Назовите что-то, что можно взять в поход вместо гитары","id": "29"},
      {"prompt": "Одним словом... Назовите что-то, что может быть кривым","id": "30"},
      {"prompt": "Одним словом... Назовите что-то, что принято менять раз в год","id": "31"},
      {"prompt": "Одним словом... Назовите самый опасный вид спорта","id": "32"},
      {"prompt": "Одним словом... Назовите самый красивый цветок для составления букета","id": "33"},
      {"prompt": "Одним словом... Назовите что-то, с чем вы едите блинчики","id": "34"},
      {"prompt": "Одним словом... Назовите что-то, что должно находиться на идеальной фотографии пейзажа","id": "35"},
      {"prompt": "Одним словом... Назовите кого-то, кто может выручить в сложной ситуации","id": "36"},
      {"prompt": "Одним словом... Назовите вещь, которая постоянно у вас теряется","id": "37"},
      {"prompt": "Одним словом... Назовите что-то, что не существовало до 2020 года","id": "38"},
      {"prompt": "Одним словом... Назовите что-то, что вы возьмёте с собой в Антарктику","id": "39"},
      {"prompt": "Одним словом... Назовите что-то, что может быть на детском празднике","id": "40"},
      {"prompt": "Одним словом... Назовите что-то, что вы можете найти на кухне","id": "41"},
      {"prompt": "Одним словом... Назовите любое мифическое существо","id": "42"},
      {"prompt": "Одним словом... Назовите что-то, что вы можете найти на ферме","id": "43"},
      {"prompt": "Одним словом... Назовите что-то, что может быть острым","id": "44"},
      {"prompt": "Одним словом... Назовите что-то, что можно хранить в коробке от обуви","id": "45"},
      {"prompt": "Одним словом... Назовите что-то, что ВСЕГДА придаёт вам радость и улыбку","id": "46"},
      {"prompt": "Назовите одно слово, с которого не стоит начинать разговор, входя к друзьям в комнату","id": "47"},
      {"prompt": "Одним словом... Назовите что-то, через что вы смогли бы перепрыгнуть","id": "48"},
      {"prompt": "Одним словом... Назовите что-то, что есть на каждой свадьбе","id": "49"},
      {"prompt": "Назовите одно слово, которое дети произносят первым","id": "50"}
      ]
  }
};


const root = ReactDOM.createRoot(document.getElementById('root'));


async function start(){
  await loadSurveys();
  console.log('balls')
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

start();

root.render(
  <React.StrictMode>
    <h1>Загрузка базы вопросов...</h1>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export {surveys}